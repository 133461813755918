export default {
    namespaced: true,
    state: {
        detail: {},
        details: [],
        materials: [],
        edges: [],
        screeds: [],
        detailsNames: [],
        defaultMaterial: null,
        modal: '',
        defaultEdges: null,
        order: null,
        response: null,
        responseDebug: null,
        colors: [
            '#1abc9c', '#c700fa', '#2746ac', '#9b59b6', '#34495e', '#e74c3c',
            '#e67e22', '#f39c12', '#7f8c8d', '#f368e0', '#341f97', '#6D214F',
            '#BDC581', '#fdaf00', '#795548', '#c0392b', '#0984e3', '#55efc4',
            '#fdcb6e', '#e84393', '#2d3436',
        ]
    },
    getters: {
        materials(state) {
            return state.materials
        },
        detail(state) {
            return state.detail
        },
        edges(state) {
            return state.edges
        },
        screeds(state) {
            return state.screeds
        },
        detailsNames(state) {
            return state.detailsNames
        },
        defaultMaterial(state) {
            let check = false;
            if(state.defaultMaterial) {
                check = state.materials.find((el) => el.ref === state.defaultMaterial.ref)
            }
            return check ? state.defaultMaterial : (state.materials[0] || null);
        },
        defaultEdge(state) {
            let check = false;
            if(state.defaultEdges) {
                check = state.edges.find((el) => el.ref === state.defaultEdges.ref)
            }
            return check ? state.defaultEdges : (state.edges[0] || null);
        }
    },
    mutations: {
        SET_DETAIL(state, value) {
            state.detail = value;
        },
        SET_MODAL(state, value) {
            state.modal = value;
        },
        SET_RESPONSE(state, value) {
            state.response = value;
        },
        SET_RESPONSE_DEBUG(state, value) {
            state.responseDebug = value;
        },
        SET_ORDER(state, value) {
            state.order = value;
        },
        SET_DETAILS(state, value) {
            state.details = value;
        },
        SET_DEFAULT_MATERIALS(state, value) {
            state.defaultMaterial = value;
        },
        SET_DEFAULT_EDGES(state, value) {
            state.defaultEdges = value;
        },
        SET_MATERIALS(state, value) {
            state.materials = value
        },
        ADD_MATERIALS(state, value) {
            state.materials.push(value)
        },
        REMOVE_MATERIAL(state, value) {
            state.materials.splice(value, 1)
        },
        SET_EDGES(state, value) {
            state.edges = value.map((val, i) => {
                val['color'] = state.colors[i];
                return val
            })
        },
        ADD_EDGES(state, value) {
            value['color'] = state.colors[state.edges.length + 1]
            state.edges.push(value)
        },
        SET_SCREEDS(state, value) {
            state.screeds = value
        },
        SET_NAMES(state, value) {
            state.detailsNames = value
        },
        ADD_SCREED(state, value) {
            state.screeds.push(value)
        },
        REMOVE_SCREED(state, value) {
            state.screeds.splice(value, 1)
        },
    }
}