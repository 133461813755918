<template>
  <div class="main-panel mt-5">
    <div>
      <b-row>
        <b-col lg="3" class="pb-2">
          <h2>Список виробів</h2>
        </b-col>
        <b-col lg="9" class="pb-2">
          <b-button :to="{name:'Products-create'}"
                    variant="outline-danger">Додати виріб
          </b-button>

          <b-button v-show="checkedList.length > 0" class="ml-3" @click="createOrder"
                    variant="outline-success">Створити замовлення
          </b-button>
        </b-col>
      </b-row>


      <b-table :items="products"
               :current-page="currentPage"
               :per-page="0"
               :fields="fields"
               :busy="loading" class="mt-3" outlined>

        <!-- A virtual column -->
        <template #cell(#)="data">
          <b-form-checkbox v-if="data.item.details.length > 0" :value="data.item.id" v-model="checkedList"
                           size="lg"></b-form-checkbox>
        </template>
        <template #cell(details)="data">
          {{
            data.item.details.filter(detail => detail.detail_name != null).map(detail => detail.detail_name).join(', ')
          }}
        </template>
        <template #cell(Дії)="data">
          <router-link :to="{name:'Products-update', params : {id : data.item.id}}" class="btn btn-outline-primary btn-sm mr-2">
            <i class="fa fa-pen"></i>
          </router-link>
          <b-button @click="deleteOrder(data.item.id)" variant="outline-danger" size="sm">
            <i class="fa fa-trash"></i>
          </b-button>
        </template>

        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
      </b-table>

    </div>
    <order-form-modal
        @on-submit="sendOrder"
        @on-close="modalShow = false"
        :products="checkedProducts"
        :order="orderData"
        :text="textModal"
        :errorSize="errorSize"
        :visible="modalShow"/>
  </div>
</template>
x
<script>
import {productService} from "../../services/productService";
import productForm from "./Form.vue"
import orderFormModal from "../Order/OrderModal.vue"
import {orderService} from "../../services/orderService";


export default {
  components: {
    productForm,
    orderFormModal,
  },
  data() {
    return {
      modalShow: false,
      errorSize: false,
      textModal: '',
      loading: false,
      checkedList: [],
      order: {},
      orderData: {},
      products: [],
      currentPage: 1,
      totalPages: 0,
      recordsPerPage: 20,
      params: '',
      modalValid: true,
      fields: [
        '#',
        {'label': 'Назва виробу', key: 'name'},
        {'label': 'Деталі', key: 'details'},
        'Дії',
      ]
    }
  },
  created() {
    this.fetchData()
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.params = `?page=${value}&size=${this.recordsPerPage}`
        this.fetchData()
      }
    }
  },
  computed: {
    checkedProducts() {
      return this.checkedList.map(index => this.products.find(product => product.id == index))
    }
  },
  methods: {
    createOrder(data = {}) {
      this.order = data;
      this.orderData = this.getOrderObject();
      this.modalShow = true;
      this.validateOrder().then(() => {
        // this.sendOrder(this.orderData);
      }).catch((response) => {
        // this.modalShow = true;
      })
    },
    sendOrder(data, products) {
      this.modalShow = false;
      if(this.errorSize) return;
      let items = [];
      this.checkedList.forEach(index => {
        let item = this.products.find(product => product.id == index);
        item.details.forEach(temp => {
          temp.count = (temp.count || 1) * item.count;
          items.push(temp);
        })

      });

      this.orderData.items = items;
      this.loading = true;
      this.orderData.customer_material = data.customer_material;
      this.orderData.customer_edge = data.customer_edge;
      this.orderData.glue = data.glue;
      orderService.create(this.orderData).then((res) => {
        let orderId = res.order.id;
        this.$router.push({name: 'order-edit', params: {id: orderId}})
      })
    },
    validateOrder() {
      let customer_material = null, customer_edge = null, glue = null, edge_size = null;
      let errorGlue = false;
      this.errorSize = false;
      let errorMaterial = false;
      let errorEdge = false;
      this.checkedList.forEach(index => {
        let item = this.products.find(product => product.id == index);

        if (glue != null && glue != item.glue) {
          errorGlue = true;
          this.modalValid = false;
        } else {
          glue = item.glue

        }
        if (edge_size != null && edge_size != item.edge_size) {
          this.errorSize = true;
          this.modalValid = false;
        } else {
          edge_size = item.edge_size
        }

        if (customer_material != null && customer_material != item.customer_material) {
          errorMaterial = true;
          this.modalValid = false;
        } else {
          customer_material = item.customer_material;
        }

        if (customer_edge != null && customer_edge != item.customer_edge) {
          errorEdge = true;
          this.modalValid = false;
        } else {
          customer_edge = item.customer_edge
        }
      });

      if (errorGlue || this.errorSize) {
        let text = 'Параметри ';
        if (errorGlue) {
          text += '"Оклеювання клеєм PUR" '
        }
        if (errorGlue && errorSize) {
          text += ' та '
        }
        if (this.errorSize) {
          text += ' "Розмір з Кромкою"'
        }
        text += ' мають бути однакові у всіх виробах. Зробіть зміни';
        return Promise.reject({type: 'error', message: text});
      } else if (errorEdge || errorMaterial) {
        let text = 'Параметри ';
        if (errorEdge) {
          text += '"Матеріал Клієнта" '
        }
        if (errorMaterial && errorEdge) {
          text += ' та '
        }
        if (errorMaterial) {
          text += ' "Кромка Клієнта"'
        }

        text += ' різні для виробів, в замовлення параметри не будуть передані';
        return Promise.reject({type: 'notify', message: text});
      }

      return Promise.resolve()


    },
    getOrderObject() {
      let production_params, cli, data, materials = [], edges = [],
          names = [], screeds = [], details = [],
          glue = true, customer_material = true, customer_edge = true, edge_size = true;
      production_params = this.$store.state.auth.user.client.production_params[0];
      let detailIteration = 0;
      let notCheck = [];
      this.checkedList.forEach((index, key) => {
        let item = this.products.find(product => product.id == index);
        if(glue) {
          glue = item.glue;
        }
        if(customer_material) {
          customer_material = item.customer_material;
        }
        if(customer_edge) {
          customer_edge = item.customer_edge;
        }
        if(edge_size) {
          edge_size = item.edge_size;
        }
        names.push({name: item.name, id:item.id})

        item.details.forEach((detail) => {

          if (detail.edge_l != null) {
            if (edges.filter(ref => ref === detail.edge_l).length === 0) {
              edges.push(detail.edge_l)
            }
          }
          if (detail.edge_b != null) {
            if (edges.filter(ref => ref === detail.edge_b).length === 0) {
              edges.push(detail.edge_b)
            }
          }

          if (detail.edge_t != null) {
            if (edges.filter(ref => ref === detail.edge_t).length === 0) {
              edges.push(detail.edge_t)
            }
          }
          if (detail.edge_r != null) {
            if (edges.filter(ref => ref === detail.edge_r).length === 0) {
              edges.push(detail.edge_r)
            }
          }

          if (detail.edge_template != null) {
            if (edges.filter(ref => ref === detail.edge_template).length === 0) {
              edges.push(detail.edge_template)
            }
          }

          if (detail.material != null) {
            if (materials.filter(ref => ref === detail.material).length === 0) {
              materials.push(detail.material)
            }
          }
          if (detail.screed != null && [0,1].indexOf(detail.screed) === -1) {
            if (screeds.filter(scr => scr.No === detail.screed).length === 0) {
              screeds.push({No: detail.screed});
            } else if (detailIteration !== key &&
                notCheck.indexOf(detail.id) === -1 &&
                screeds.filter(scr => scr.No === detail.screed).length !== 0) {

              let max = screeds.map(i => i.No).reduce((a, b) => Math.max(a, b), -Infinity);
              let currentScreed = detail.screed;
              screeds.push({No: ++max});
              item.details.forEach((ttt, index) => {
                if(ttt.screed === currentScreed) {

                  item.details[index].screed = max;
                  notCheck.push(ttt.id)
                }
              })

            }
          }

          details.push(detail)
        });
        detailIteration = key;

      })
      let firstItem = screeds.map(i => i.No).indexOf(1);
      if(firstItem !== -1) {
        screeds.splice(firstItem, 1);
      }

      cli = {
        "dept": "ТПМ",
        "not_show": true,
        "section": production_params.section,
        "customer": this.$store.state.auth.user.client.ref,
        "stock": this.$store.state.auth.user.defaultStock,
        "cy": "980",//980
        "manager": production_params.manager,
        "price_type": production_params.price_type,
        "draw_cut": false,
        "to_work": false,
        "check_parts": false,
        "materials_list": materials,
        "edge_list": edges,
        "screed_list": screeds,
        "name_list": names,
        "glue": glue,
        "customer_material": customer_material,
        "customer_edge": customer_edge,
        "edge_size": edge_size,
      };
      this.order.items = details;
      data = {
        ...this.order,
        ...cli,
      };

      return data;
    },
    deleteOrder(id) {
      this.$bvModal.msgBoxConfirm('Ви впевнені, що хочете видалити виріб?', {
        title: 'Підтвердіть',
        size: 'mm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Так',
        cancelTitle: 'Ні',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
          .then((value) => {
            if (value) {
              this.loading = true;
              productService.deleteProduct(id).then(response => {
                this.$toast.success("Успішно видалено");
                this.fetchData();
              }).then(r => {
              }).catch(e => {
                this.loading = false;
                this.$toast.error("Щось пішло не так =(");
              });
            }
          })
    },
    fetchData() {
      this.loading = true;
      productService.getListProducts(this.params).then((response) => {
        if (response.data) {
          this.products = response.data
          this.totalPages = response.meta.total
          this.loading = false
        }
      });
    }
  },
  name: "product-list"
}
</script>

<style scoped>
</style>